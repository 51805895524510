(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("CignaIM"));
	else if(typeof define === 'function' && define.amd)
		define(["CignaIM"], factory);
	else if(typeof exports === 'object')
		exports["ui"] = factory(require("CignaIM"));
	else
		root["ui"] = factory(root["CignaIM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 